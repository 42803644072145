<template>
  <div id="microapp-viewport" />
</template>

<script>
import microAppStart from '@/qiankun';

export default {
  setup() {
    if (!window.__qiankun_started__) {
      window.__qiankun_started__ = true;
      microAppStart();
    }
  },
};
</script>

<style lang="less" scoped>
#microapp-viewport {
  height: 100%;
}
</style>

'use strict';

import store from '@/store';
import router from '@/router';

/**
 * @name 声明一个常量准备将props内的部分内容储存起来
 */
const STORE = {};

/**
 * @name 启动qiankun应用间通信机制
 * @param {Function} initGlobalState 官方通信函数
 * @description 注意：主应用是从qiankun中导出的initGlobalState方法，
 * @description 注意：子应用是附加在props上的onGlobalStateChange, setGlobalState方法（只用主应用注册了通信才会有）
 */
export default initGlobalState => {
  const { tenant, token, language } = store.state.account;
  /**
   * @name 初始化数据内容
   */
  const { onGlobalStateChange, setGlobalState } = initGlobalState({
    ignore: 'master',
    tenant,
    token,
    language,
    breadcrumbs: null,
    noToken: false,
  });

  /**
   * @name 监听数据变动
   * @param {Function} 监听到数据发生改变后的回调函数
   * @des 将监听到的数据存入vuex
   */
  onGlobalStateChange((value, prev) => {
    console.log('[onGlobalStateChange - master]:', value, prev);
    store.commit('common/setBreadcrumbs', value.breadcrumbs);
    value.noToken && router.push('/login');
  });

  /**
   * @name 将你需要的数据存起来，供下面setState方法使用
   */
  STORE.setGlobalState = setGlobalState;
};

/**
 * @name 全局setQKState方法，修改的内容将通知所有微应用
 * @param {Object} data 按照你设定的内容格式数据
 */
export const setQKState = data => {
  STORE.setGlobalState({ ignore: 'master', ...data });
};
